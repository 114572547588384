<template>
  <a
    v-if="collection && collection !== 'none'"
    :class="[
      'atom atom-collection-button self-center inline-block px-3 py-1 mb-4 text-black bg-white rounded-full',
      disableShadow ? 'drop-shadow-none' : 'drop-shadow',
    ]"
    :title="'Explore ' + collection"
  >
    <!--<img
      class="inline-block w-5"
      :src="collectionComputed?.sourceUrl"
      :alt="collectionComputed?.alt"
    />-->
    <span
      :class="[
        'text-sm font-figtree font-semibold',
        coloredText ? collectionComputed?.textClass : 'text-black',
      ]"
    >
      {{ collectionComputed?.collectionTitle }}
    </span>
  </a>
</template>

<script setup>
const props = defineProps({
  collection: String,
  disableShadow: Boolean,
  coloredText: Boolean, // Defaults to black text if false
});

const collectionComputed = computed(() => getCollectionIcon(props?.collection));

//:href="false ? collectionComputed?.link : '#'" for the a tag above if needed back
</script>
